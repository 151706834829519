import * as React from "react"

import Layout from "../components/layout"
import "../components/about.css"
import HeaderDark from "../components/headerdark"
import Seo from "../components/seo"
import { Link } from 'gatsby';


const About = () => (
    <Layout>
      
      <HeaderDark/>

        <div className="About">
          <div className="AboutGroup">
          <Link className="link-styles" to="#contact"> Contact</Link>
            <h1>Deniz Onmuş</h1>
            <h2>Hello!</h2>
            <p>I am an animator and visual development artist.</p>
            <p>I have graduated from the "School of Visual Arts" in New York with a BFA in Animation, and have been working freelance for over a year. </p>
            <p>I love making things and learning, here's to more of that in the future.</p>
            
          </div>
        </div>
        <div className="Bio">
            <p>I am an animator and visual development artist. </p>
            <p>I have graduated from the "School of Visual Arts" in New York with a BFA in Animation, and have been working freelance for over a year. </p>
            <p>I love making things and learning, here's to more of that in the future.</p>
          </div>

          
    </Layout>
)

export const Head = () => <Seo title="About" />

export default About
